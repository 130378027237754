// ** Initial State
const initialState = {
  data: [],
  total: 1,
  fetched: false,
  params: {},
}

const datatableReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA':
        const resData = action.data
      return {
        ...state,
        allData: resData.allData,
        data: resData.data,
        fetched: true,
        total: resData.total,
        params: resData.params
      }
    default:
      return state
  }
}

export default datatableReducer
