// ** Initial State
const initialState = {
  product: {
    features:[],
    settings:[],
    is_fetched:false
  },
}

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_WEB_SETTING':
      action.product.is_fetched = true;
      if(!action.isError){
        return { ...state,
          product: action.product }
      }else{
        return { ...state,
          product: initialState.product }
      }

    default:
      return state
  }
}

export default productReducer
