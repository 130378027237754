import mock from '../mock'
const data = {
  accountSetting: {
    general: {
      avatar: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
      fullName: 'John Doe',
      email: 'granger007@hogward.com',
      phone: '9876543210'
    },
    info: {
      bio: '',
      dob: null,
      country: 'USA',
      website: '',
      phone: 6562542568
    }
    
  }
}

mock.onGet('/account-setting/data').reply(() => [200, data.accountSetting])
