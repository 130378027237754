import mock from '../mock'
import { paginateArray } from '../utils'

const data = [
  {
    responsive_id: '',
    id: 1,
    journey_type: 'One Way',
    mov_id:'MV-1105',
    reference: 'BK-0007',
    dispatch_date: '12/03/2021',
    dispatch_time: '12:00',
    from: 'New York-New York Hotel & Casino, South Las Vegas Boulevard, Las Vegas, NV, USA',
    to: 'New York New York Casino, Las Vegas, NV, USA',
    pax_name:'Amy A. Kyler',
    pax_phone:'580-675-7318',
    pax_email:'AmyAKyler@dayrep.com',
    fare: '$152.32',
    status: 1,
    pax_no: '2',
    lug_no:'3',
    baby_no:'1',
    Booked_date: '10/03/2021 ',
    Booked_time: '22:00 ',
    zone:'Ritz Carlton Club',
    vehicle_type: 'Executive Sedan',
    vehicle_class: 'Economy Class',
    vehicle_reg: 'V-96325',
    vehicle_no:'2',
    flight_name:'British Airways',
    flight_no:'BA101',
    flight_arrival_time: '13:45',
    message: 'This is message Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur ',
    payment_type: 2,
    payment:1,
    driver_name:'Kelly J. Merritt',
    driver_email:'KellyJMerritt@armyspy.com',
    driver_phone:'805-814-7733',
    driver_license_no:'1Z F14 239'
  },
  {
    responsive_id: '',
    id: 2,
    journey_type: 'One Way',
    mov_id:'MV-1107',
    reference: 'BK-0002',
    dispatch_date: '01/03/2022',
    dispatch_time: '19:55',
    from: 'Eiffel Tower, Avenue Anatole France, Paris, France',
    to: 'Paris Expo Porte de Versailles, Place de la Porte de Versailles, Paris, France',
    pax_name: 'Matt N. Talbot',
    pax_phone:'732-517-4174',
    pax_email:'MattNTalbot@teleworm.us',
    fare: '$1100.21',
    status: 2,
    pax_no: '2',
    lug_no:'3',
    baby_no:'1',
    Booked_date: '10/03/2021 ',
    Booked_time: '22:00 ',
    zone:'Ritz Carlton Club',
    vehicle_type: 'Executive Sedan',
    vehicle_class: 'Economy Class',
    vehicle_reg: 'V-96325',
    vehicle_no:'2',
    flight_name:'British Airways',
    flight_no:'BA101',
    flight_arrival_time: '13:45',
    message: 'This is message',
    payment_type: 2,
    payment:1,
    driver_name:'Kelly J. Merritt',
    driver_email:'KellyJMerritt@armyspy.com',
    driver_phone:'805-814-7733',
    driver_license_no:'1Z F14 239'
  },
  {
    responsive_id: '',
    id: 3,
    mov_id:'MV-1109',
    journey_type: 'One Way',
    reference: 'BK-00011',
    dispatch_date: '02/03/2022',
    dispatch_time: '17:35',
    from: 'London SW11, UK',
    to: 'London SW13 9WT, UK',
    pax_name: 'Leon J. Shockey',
    pax_phone:'425-299-2475',
    pax_email:'LeonJShockey@armyspy.com',
    fare: '$852.23',
    status: 3,
     pax_no: '2',
    lug_no:'3',
    baby_no:'1',
    Booked_date: '10/03/2021 ',
    Booked_time: '22:00 ',
    zone:'Ritz Carlton Club',
    vehicle_type: 'Executive Sedan',
    vehicle_class: 'Economy Class',
    vehicle_reg: 'V-96325',
    vehicle_no:'2',
    flight_name:'British Airways',
    flight_no:'BA101',
    flight_arrival_time: '13:45',
    message: 'This is message',
    payment_type: 2,
    payment:2,
    driver_name:'Kelly J. Merritt',
    driver_email:'KellyJMerritt@armyspy.com',
    driver_phone:'805-814-7733',
    driver_license_no:'1Z F14 239'
  },
  {
    responsive_id: '',
    id: 4,
    mov_id:'MV-1115',
    journey_type: 'One Way',
    reference: 'BK-0011', 
    dispatch_date: '11/03/2021',
    dispatch_time: '03:40',
    from: 'London Bridge, London, UK',
    to: 'Big Ben, London, UK',
    pax_name: 'Troy W. Anderson',
    pax_phone:'205-412-3324',
    pax_email:'TroyWAnderson@rhyta.com',
    fare: '$112.32',
    status: 4,
     pax_no: '2',
    lug_no:'3',
    baby_no:'1',
    Booked_date: '10/03/2021 ',
    Booked_time: '22:00 ',
    zone:'Ritz Carlton Club',
    vehicle_type: 'Executive Sedan',
    vehicle_class: 'Economy Class',
    vehicle_reg: 'V-96325',
    vehicle_no:'2',
    flight_name:'British Airways',
    flight_no:'BA101',
    flight_arrival_time: '13:45',
    message: 'This is message',
    payment_type: 3,
    payment:2,
    driver_name:'Kelly J. Merritt',
    driver_email:'KellyJMerritt@armyspy.com',
    driver_phone:'805-814-7733',
    driver_license_no:'1Z F14 239'
  },
  {
    responsive_id: '',
    id: 5,
    mov_id:'MV-2255',
    journey_type: 'Two Way',
    reference: 'BK-0031',
    dispatch_date: '02/03/2022',
    dispatch_time: '23:00',
    from: 'Opera House Manchester, Quay Street, Manchester, UK',
    to: 'Manchester Christmas Market, Albert Square, Manchester, UK',
    pax_name: 'Carl M. Judah', 
    pax_phone:'512-224-8295',
    pax_email:'CarlMJudah@jourrapide.com',
    fare: '$8899.03',
    status: 3,
     pax_no: '2',
    lug_no:'3',
    baby_no:'1',
    Booked_date: '10/03/2021 ',
    Booked_time: '22:00 ',
    zone:'Ritz Carlton Club',
    vehicle_type: 'Executive Sedan',
    vehicle_class: 'Economy Class',
    vehicle_reg: 'V-96325',
    vehicle_no:'2',
    flight_name:'British Airways',
    flight_no:'BA101',
    flight_arrival_time: '13:45',
    message: 'This is message',
    payment_type: 2,
    payment:2,
    driver_name:'Kelly J. Merritt',
    driver_email:'KellyJMerritt@armyspy.com',
    driver_phone:'805-814-7733',
    driver_license_no:'1Z F14 239'
  },
   {
    responsive_id: '',
    id: 6,
    mov_id:'MV-0015',
    journey_type: 'Two Way',
    reference: 'BK-0131',
    dispatch_date: '02/22/2022',
    dispatch_time: '11:55',
    from: 'Geelong VIC, Australia',
    to: 'Sydney Opera House, Sydney NSW, Australia',
    pax_name: 'Carl M. Judah', 
    pax_phone:'512-224-8295',
    pax_email:'CarlMJudah@jourrapide.com',
    fare: '$8899.03',
    status: 2,
     pax_no: '2',
    lug_no:'3',
    baby_no:'1',
    Booked_date: '10/03/2021 ',
    Booked_time: '22:00 ',
    zone:'Ritz Carlton Club',
    vehicle_type: 'Executive Sedan',
    vehicle_class: 'Economy Class',
    vehicle_reg: 'V-96325',
    vehicle_no:'2',
    flight_name:'British Airways',
    flight_no:'BA101',
    flight_arrival_time: '13:45',
    message: 'This is message',
    payment_type: 3,
    payment:1,
    driver_name:'Kelly J. Merritt',
    driver_email:'KellyJMerritt@armyspy.com',
    driver_phone:'805-814-7733',
    driver_license_no:'1Z F14 239'
  },
   {
    responsive_id: '',
    id: 7,
    mov_id:'MV-8562',
    reference: 'BK-0331',
    journey_type: 'One Way',
    dispatch_date: '01/13/2022',
    dispatch_time: '23:00',
    from: 'Sydney Fish Market, Bank Street, Pyrmont NSW, Australia',
    to: 'Sydney Airport (SYD), Sydney NSW, Australia',
    pax_name: 'Carl M. Judah', 
    pax_phone:'512-224-8295',
    pax_email:'CarlMJudah@jourrapide.com',
    fare: '$8899.03',
    status: 1,
    pax_no: '2',
    lug_no:'3',
    baby_no:'1',
    Booked_date: '10/03/2021 ',
    Booked_time: '22:00 ',
    zone:'Ritz Carlton Club',
    vehicle_type: 'Executive Sedan',
    vehicle_class: 'Economy Class',
    vehicle_reg: 'V-96325',
    vehicle_no:'2',
    flight_name:'British Airways',
    flight_no:'BA101',
    flight_arrival_time: '13:45',
    message: 'This is message',
    payment_type: 1,
    payment:1,
    driver_name:'Kelly J. Merritt',
    driver_email:'KellyJMerritt@armyspy.com',
    driver_phone:'805-814-7733',
    driver_license_no:'1Z F14 239'
  },
   {
    responsive_id: '',
    id: 8,
    mov_id:'MV-5505',
    reference: 'BK-1131',
    journey_type: 'Two Way',
    dispatch_date: '02/03/2022',
    dispatch_time: '23:00',
    from: 'Opera House Manchester, Quay Street, Manchester, UK',
    to: 'Manchester Christmas Market, Albert Square, Manchester, UK',
    pax_name: 'Carl M. Judah', 
    pax_phone:'512-224-8295',
    pax_email:'CarlMJudah@jourrapide.com',
    fare: '$8899.03',
    status: 3,
     pax_no: '2',
    lug_no:'3',
    baby_no:'1',
    Booked_date: '10/03/2021 ',
    Booked_time: '22:00 ',
    zone:'Ritz Carlton Club',
    vehicle_type: 'Executive Sedan',
    vehicle_class: 'Economy Class',
    vehicle_reg: 'V-96325',
    vehicle_no:'2',
    flight_name:'British Airways',
    flight_no:'BA101',
    flight_arrival_time: '13:45',
    message: 'This is message',
    payment_type: 2,
    payment:2,
    driver_name:'Kelly J. Merritt',
    driver_email:'KellyJMerritt@armyspy.com',
    driver_phone:'805-814-7733',
    driver_license_no:'1Z F14 239'
  },
   {
    responsive_id: '',
    id: 9,
    mov_id:'MV-1805',
    reference: 'BK-8940',
    journey_type: 'One Way',
    dispatch_date: '02/03/2022',
    dispatch_time: '23:00',
    from: 'Opera House Manchester, Quay Street, Manchester, UK',
    to: 'Manchester Christmas Market, Albert Square, Manchester, UK',
    pax_name: 'Carl M. Judah', 
    pax_phone:'512-224-8295',
    pax_email:'CarlMJudah@jourrapide.com',
    fare: '$8899.03',
    status: 4,
   pax_no: '2',
    lug_no:'3',
    baby_no:'1',
    Booked_date: '10/03/2021 ',
    Booked_time: '22:00 ',
    zone:'Ritz Carlton Club',
    vehicle_type: 'Executive Sedan',
    vehicle_class: 'Economy Class',
    vehicle_reg: 'V-96325',
    vehicle_no:'2',
    flight_name:'British Airways',
    flight_no:'BA101',
    flight_arrival_time: '13:45',
    message: 'This is message',
    payment_type: 1,
    payment:1,
    driver_name:'Kelly J. Merritt',
    driver_email:'KellyJMerritt@armyspy.com',
    driver_phone:'805-814-7733',
    driver_license_no:'1Z F14 239'
  },
   {
    responsive_id: '',
    id: 10,
    mov_id:'MV-1905',
    reference: 'BK-9654',
    journey_type: 'Two Way',
    dispatch_date: '02/03/2022',
    dispatch_time: '23:00',
    from: 'Opera House Manchester, Quay Street, Manchester, UK',
    to: 'Manchester Christmas Market, Albert Square, Manchester, UK',
    pax_name: 'Carl M. Judah', 
    pax_phone:'512-224-8295',
    pax_email:'CarlMJudah@jourrapide.com',
    fare: '$8899.03',
    status: 2,
    pax_no: '2',
    lug_no:'3',
    baby_no:'1',
    Booked_date: '10/03/2021 ',
    Booked_time: '22:00 ',
    zone:'Ritz Carlton Club',
    vehicle_type: 'Executive Sedan',
    vehicle_class: 'Economy Class',
    vehicle_reg: 'V-96325',
    vehicle_no:'2',
    flight_name:'British Airways',
    flight_no:'BA101',
    flight_arrival_time: '13:45',
    message: 'This is message',
    payment_type: 2,
    payment:2,
    driver_name:'Kelly J. Merritt',
    driver_email:'KellyJMerritt@armyspy.com',
    driver_phone:'805-814-7733',
    driver_license_no:'1Z F14 239'
  },
   {
    responsive_id: '',
    id: 11,
    mov_id:'MV-3574',
    journey_type: 'One Way',
    reference: 'BK-0031',
    dispatch_date: '02/03/2022',
    dispatch_time: '23:00',
    from: 'Opera House Manchester, Quay Street, Manchester, UK',
    to: 'Manchester Christmas Market, Albert Square, Manchester, UK',
    pax_name: 'Carl M. Judah', 
    pax_phone:'512-224-8295',
    pax_email:'CarlMJudah@jourrapide.com',
    fare: '$8899.03',
    status: 2,
     pax_no: '2',
    lug_no:'3',
    baby_no:'1',
    Booked_date: '10/03/2021 ',
    Booked_time: '22:00 ',
    zone:'Ritz Carlton Club',
    vehicle_type: 'Executive Sedan',
    vehicle_class: 'Economy Class',
    vehicle_reg: 'V-96325',
    vehicle_no:'2',
    flight_name:'British Airways',
    flight_no:'BA101',
    flight_arrival_time: '13:45',
    message: 'This is message',
    payment_type: 3,
    payment:2,
    driver_name:'Kelly J. Merritt',
    driver_email:'KellyJMerritt@armyspy.com',
    driver_phone:'805-814-7733',
    driver_license_no:'1Z F14 239'
  }
]

mock.onGet('/api/datatables/initial-data').reply(config => {
  return [200, data]
})

mock.onGet('/api/datatables/data').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1 } = config
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.filter(
    item =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      item.full_name.toLowerCase().includes(queryLowered) ||
      item.post.toLowerCase().includes(queryLowered) ||
      item.email.toLowerCase().includes(queryLowered) ||
      item.age.toLowerCase().includes(queryLowered) ||
      item.salary.toLowerCase().includes(queryLowered) ||
      item.start_date.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      allData: data,
      invoices: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})
